@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
@import url('./font.css');
* {
  font-family: 'Gilroy', sans-serif;
  box-sizing: border-box;
}
html {
  overflow: scroll;
  scroll-behavior: smooth;
  padding: 0;
}
body {
  padding: 0;
  font-family: 'Gilroy';
  font-style: normal;
  font-size: 14px;
  background-color: #f2f4f7;
  min-width: 330px;
  color: #000;
  line-height: normal;
  overflow-x: hidden;
}
body.no-scroll {
  overflow: hidden;
}
ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}
a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease;
}
button {
  padding: 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
  line-height: inherit;
  transition: all 0.3s ease;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  padding: 0;
}

input,
textarea {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  color: inherit;
  transition: all 0.3s ease;
}
input:focus,
textarea:focus {
  outline: none;
  transition: all 0.3s ease;
}
textarea:focus::placeholder {
  opacity: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}
textarea {
  resize: none;
}
img {
  max-width: 100%;
  object-fit: cover;
}
svg {
  transition: all 0.3s ease;
}
.flex {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
}
