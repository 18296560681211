@font-face {
  font-family: 'Gilroy';
  src:
    local('Gilroy-Light'),
    url('../assets/fonts/Gilroy-Light.woff2') format('woff2'),
    url('../assets/fonts/Gilroy-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src:
    local('Gilroy-Regular'),
    url('../assets/fonts/Gilroy-Regular.woff2') format('woff2'),
    url('../assets/fonts/Gilroy-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src:
    local('Gilroy-Medium'),
    url('../assets/fonts/Gilroy-Medium.woff2') format('woff2'),
    url('../assets/fonts/Gilroy-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src:
    local('Gilroy-SemiBold'),
    url('../assets/fonts/Gilroy-Semibold.woff2') format('woff2'),
    url('../assets/fonts/Gilroy-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gilroy';
  src:
    local('Gilroy-Bold'),
    url('../assets/fonts/Gilroy-Bold.woff2') format('woff2'),
    url('../assets/fonts/Gilroy-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
